<template>
  <div class="users">
    <Header title="汇报管理" index="首页" beforeTitle="汇报管理" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select v-model="reportParams.type" placeholder="全部汇报类型" @change="changeType">
            <el-option label="全部" value="0"></el-option>
            <el-option label="日报" value="1">日报</el-option>
            <el-option label="周报" value="2">周报</el-option>
            <el-option label="月报" value="3">月报</el-option>
          </el-select>
          <el-select v-model="reportParams.time_type" placeholder="全部时间段" @change="changetimeType">
            <el-option label="全部" value="0"></el-option>
            <el-option label="本周" value="1">本周</el-option>
            <el-option label="上周" value="2">上周</el-option>
            <el-option label="本月" value="3">本月</el-option>
            <el-option label="上月" value="4">上月</el-option>
          </el-select>
          <el-input
            v-model="reportParams.keywords"
            placeholder="请输入人员名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button
          class="add"
          v-if="privilige_list.report.includes('add')"
          @click="handleEdit('')"
        >新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="reportdata.data"
        style="width: 100%"
      >
        <el-table-column prop="title" label="汇报人"></el-table-column>
        <el-table-column label="部门/角色">
          <template
            slot-scope="scope"
          >{{ scope.row.organization_name + ' / ' + scope.row.role_name }}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="汇报时间">
          <template slot-scope="scope">{{ scope.row.create_time | fmtdate }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button
              class="edit"
              size="mini"
              v-if="privilige_list.report.includes('edit')"
              @click="handleEdit(scope.row.id)"
            >编辑</el-button>
            <el-button
              class="edit"
              size="mini"
              v-if="privilige_list.report.includes('view')"
              @click="handlecheck(scope.row.id)"
            >查看</el-button>
            <el-button
              class="del"
              size="mini"
              v-if="privilige_list.report.includes('delete')"
              @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="reportdata.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="reportParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="reportParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="reportdata.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      loading: true,
      reportParams: {
        page: 1,
        limit: 10,
        keywords: "",
        type: "",
        time_type: "",
        order: "id desc",
      },
      input: "",
      privilige_list: []
    };
  },
  activated () {
    // this.handleSearch();
    this.reportParams
    this.$store.dispatch("getReportData", this.reportParams);
  },
  computed: {
    ...mapState(["reportdata"]),
  },
  methods: {
    handleSizeChange (val) {
      this.reportParams.limit = val;
      this.$store.dispatch("getReportData", this.reportParams);
    },
    handleCurrentChange (val) {
      this.reportParams.page = val;
      this.$store.dispatch("getReportData", { ...this.reportParams });
    },
    handleEdit (id) {
      this.$router.push("/report/add?id=" + id);
    },
    handlecheck (id) {
      this.$router.push("/report/check?id=" + id);
    },
    handleSearch () {
      this.$store.dispatch("getReportData", { ...this.reportParams, page: '', limit: '' });
      this.reportParams.page = 1
    },
    changeType () {
      this.$store.dispatch("getReportData", this.reportParams);
    },
    changetimeType () {
      this.$store.dispatch("getReportData", this.reportParams);
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/report/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getReportData", this.reportParams);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },

  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    this.$store.dispatch("getReportData", this.reportParams);
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>
